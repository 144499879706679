<template>
  <div class="checkout-address-selector">
    <div class="row">
      <div class="col-auto Aligner font-size-lg pe-0">
        <i class="fas fa-map-marker-alt"></i>
      </div>
      <div class="col Aligner items-left">
        <!--    Skeleton    -->
        <div class="skeleton w-100" v-if="selected_address === null || selected_address === undefined">
          <div class="line skeleton_loader dark thick w-60"></div>
          <div class="line skeleton_loader thick w-30"></div>
        </div>
        <!--    Address    -->
        <div class="address" v-if="selected_address !== null && selected_address !== undefined">
          <div><b>{{ selected_address.address_description }}</b></div>
          <div class="color-secondary">{{ selected_address.city }}, {{ selected_address.country.name }}</div>
        </div>
      </div>
      <div class="col-auto Aligner items-right">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-clean font-size-lg" data-bs-toggle="modal"
                :data-bs-target="'#addressSelectorModal_'+unique_id">
          <i class="fas fa-pencil-alt"></i>
        </button>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" :id="'addressSelectorModal_'+unique_id" tabindex="-1"
         :aria-labelledby="'addressSelectorModalLabel_'+unique_id" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-notice">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" :id="'addressSelectorModalLabel_' + unique_id">
              {{ $t("addresses.choose_your_location") }}
            </h5>
            <i
                class="fas fa-times-circle close mt-1"
                v-bind:aria-label="$t('global.cancel')"
                data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="modal-body">
            <perfect-scrollbar :options="{wheelPropagation: false}">
              <div class="addresses">
                <div class="address-input-holder" v-for="address in addresses" :key="address.id">
                  <form-check-input :value="address.id"
                                    type='radio'
                                    name="cart_address"
                                    :checked="address.id === selected_address.id"
                                    :inline="false"
                                    @changed="addressChanged(address)"
                                    >
                    <div class="body">
                      <div class="color-secondary">{{ address.city }}, {{ address.country.name }}</div>
                      <div class="font-size-md">{{ address.address_description }}</div>
                    </div>
                  </form-check-input>
                </div>
              </div>
            </perfect-scrollbar>

            <div class="w-100 float-left pt-3">
              <button @click="addNewAddress" type="button"
                      class="btn btn-clean addresses_link text-uppercase font-size-default font-weight-bold "
                      data-bs-dismiss="modal"><u class="color-secondary hover-color-black">{{ $t("addresses.create") }}</u></button>
            </div>
          </div>
          <div class="modal-footer centered-vertically">
            <button type="button" class="btn btn-primary" @click="saveSelection" data-bs-dismiss="modal"> {{$t('global.save')}} </button>
            <button type="button" class="btn btn-clean" data-bs-dismiss="modal"> {{$t('global.close')}} </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FormCheckInput from "@/components/FormCheckInput";

export default {
  name: "CheckoutAddressSelector",
  components: {FormCheckInput},
  props: {
    selected_address_id: {
      type: Number,
      default: function () {
        return null;
      }
    },
  },
  data() {
    return {
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
      selected_address: null,
      temp_selected_address: null
    }
  },
  watch: {
    //Wait for addresses to be ready
    addresses: function (newValue) {
      if (newValue.length !== 0) {
        this.findAddressById(this.selected_address_id);
      }
    }
  },
  methods: {
    addNewAddress(){
      this.$router.push("/addresses/create");
    },
    saveSelection(){
      this.selected_address = this.temp_selected_address;
      this.temp_selected_address = null;
      this.$emit("changed", this.selected_address);
    },
    addressChanged(address){
      this.temp_selected_address = address;
    },
    findAddressById(address_id) {
      if (address_id !== null) {
        //Find address by id
        this.selected_address = this.getAddressById(address_id);
      } else {
        //Set primary address
        this.selected_address = this.primaryAddress;
      }
    }
  },
  computed: {
    ...mapGetters(['addresses', 'primaryAddress', 'getAddressById'])
  },
  created() {
    this.findAddressById(this.selected_address_id);
  }
}
</script>

<style lang="scss" scoped>
.checkout-address-selector {
  padding: map-get($spacings, "small") map-get($spacings, "default");

  //Perfect Scrollbar
  .ps {
    max-height: 300px;

    .addresses {
      .address-input-holder {
        border-bottom: 1px solid map-get($defaults, "border-color");
        padding-top: map-get($spacings, "xsmall");
        padding-bottom: map-get($spacings, "xsmall");

        .body {
          margin-top:-5px;
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

  }
}
</style>
