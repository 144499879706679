<template>
  <div class="container checkout-show">
    <div class="title font-size-xl font-weight-bold">
      {{ $t("cart.checkout") }}
    </div>

    <div class="holder">
      <!-- Address selector -->
      <div class="address-selector">
        <checkout-address-selector :selected_address_id="cart_group.address_id" @changed="addressChanged" />
      </div>

      <!--   Products Skeleton  -->
      <div class="products" v-if="products.length === 0">
        <div class="product-holder">
          <cart-product-skeleton/>
        </div>
      </div>
      <!--   Products   -->
      <div class="products" v-if="products.length !== 0">
        <div class="product-holder" v-for="product in cart_group.products" :key="product.id">
          <CartProduct :editable='false'
                       :selected_extras_only="true"
                       :product_data="product"
                       :shop_id="cart_group.shop_id"/>
        </div>
      </div>


      <!--    Total bar    -->
      <cart-total-bar :data_ready="data_ready"
                    :currency="currency"
                    :delivery_price="delivery_price"
                    :payment_method="cart_group.payment_method"
                    :total_price="total_price"
                    :total_weight="total_weight"/>

      <div class="actions Aligner items-right">
        <button class="btn btn-primary" :disabled="checkout_processing"  @click="doCheckout"> {{ cart_group.payment_method === "cash" ?  $t("cart.confirm_order") : $t("global.next") }}</button>
      </div>

    </div>

  </div>
</template>

<script>
import {Promise} from "core-js";
import {mapActions, mapGetters} from "vuex";
import {formatCartGroupForApi} from "@/includes/cart";
import CheckoutAddressSelector from "@/views/Checkout/partials/CheckoutAddressSelector";
import {saveCheckoutToStorage} from "@/includes/checkout";
import CartProduct from "@/views/Cart/partials/CartProduct";
import CartProductSkeleton from "@/views/Cart/partials/CartProductSkeleton";
import CartTotalBar from "@/views/Cart/partials/CartTotalBar";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "CheckoutShow",
  components: {
    CartTotalBar,
    CartProductSkeleton,
    CartProduct,
    CheckoutAddressSelector},
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("checkout.page_title"),
    })
  },
  data() {
    return {
      checkout_processing: false,
      cart_group: null,
      data_ready: false,
      shop: [],
      products: [],
      currency: null,
      delivery_price: 0,
      total_price: 0,
      total_weight: 0,
      prescription_required: false,
    };
  },
  computed: {
    ...mapGetters(['getGroupById']),
    group_id: function () {
      return parseInt(this.$route.params.id);
    },
    formatedCartGroup() {
      return formatCartGroupForApi(this.cart_group, this.selected_address);
    },
  },
  methods: {
    ...mapActions(["setGroupAddressId","save_cart_to_storage","makeOrder",'removeGroupFromCart','setErrors']),
    addressChanged(address){
      this.cart_group.address_id = address.id;
      this.setGroupAddressId({
        group_id: this.cart_group.id,
        address_id: address.id
      });
      this.save_cart_to_storage();
    },
    async doCheckout() {
      this.checkout_processing = true;
      //DO checkout
      this.makeOrder(this.formatedCartGroup).then(order => {
        //Remove Group from cart
        this.removeGroupFromCart(this.cart_group.id);

        //Save to storage
        saveCheckoutToStorage(order.id, order.payment_method)

        //Redirect to checkout success
        this.$router.replace("/checkout/success/");
      });
    },
    checkIfPrescriptionRequired() {
      this.prescription_required = false;
      for (let productKey in this.products) {
        if (this.products[productKey].prescription_required === true) {
          this.prescription_required = true;
        }
      }
    },
    async fetch_cart(cart_block) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("fetch_cart");
        window.axios.post(url, cart_block).then(
            (response) => {
              let data = response.data.data;
              this.data_ready = true;
              this.shop = data.shop;
              this.currency = data.currency;
              this.products = data.products;
              this.delivery_price = data.delivery_price;
              this.total_price = data.total_price;
              this.total_weight = parseFloat(data.total_weight.toFixed(2));

              this.checkIfPrescriptionRequired();

              resolve(data);
            },
            (error) => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },
  },
  created() {
    this.cart_group = this.getGroupById(this.group_id);
    this.fetch_cart(this.formatedCartGroup);
  }
}
</script>

<style lang="scss" scoped>
.checkout-show {
  padding-top: map-get($spacings, "medium");
  padding-bottom: map-get($spacings, "large");

  .title {
    padding-bottom: map-get($spacings, "medium");
  }

  .holder {
    margin: 0 auto;
    width: 100%;
    max-width: 860px;

    .address-selector {
      border-bottom: 1px solid map-get($defaults, "border-color");
      margin-bottom: map-get($spacings, "small");
    }

    .products {
      .product-holder {
        border-bottom: 1px solid map-get($defaults, "border-color");
        padding-top: map-get($spacings, "small");
        padding-bottom: map-get($spacings, "small");

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .actions {
      margin-top: map-get($spacings, "seperator");
      margin-bottom: map-get($spacings, "seperator");

      .btn {
        min-width: 250px;
      }
    }
  }
}
</style>
