
const saveCheckoutToStorage = function (order_id, payment_method) {
    localStorage.setItem("checkout", JSON.stringify({
        'order_id': order_id,
        'payment_method': payment_method
    }));
}

const removeCheckoutFromStorage = function () {
    localStorage.removeItem("checkout");
}

const getCheckoutFromStorage = function () {
    let checkout = localStorage.getItem("checkout");
    if(checkout !== null && checkout !== undefined){
        return JSON.parse(checkout);
    }

    return null;
}




export {saveCheckoutToStorage, removeCheckoutFromStorage, getCheckoutFromStorage};
